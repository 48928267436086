<template>
  <div id="app">
    <header class="header-view">
      <div>通政</div>
      <div class="header-tabs-view">
        <div class="header-tabs-title" v-for="(item,index) in titleList" :key="index" @click="chooseTabs(index)">
          <div class="header-tabs-title1" :class="titleIndex == index ? 'active-color':''">{{ item.name }}</div>
          <div class="header-tabs-icons" v-if="titleIndex == index"></div>
        </div>
      </div>
    </header>
    <main>
      <img class="main-image" src="./assets/2.png" alt="" v-if="titleIndex == 0">
      <img class="main-image" src="./assets/1.png" alt="" v-else>
    </main>
    <footer class="footer-view">
      <div class="container">
        <p>Copyright @ 2017~2023 版权所有 <a href="https://beian.miit.gov.cn" style="color:#666666;text-decoration: none;" target="_blank">豫ICP备2024067345号</a></p>
      </div>
    </footer>
  </div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      titleIndex:0,
      titleList:[
        {name:'首页'},
        {name:'关于我们'}
      ]
    }
  },
  methods:{
    chooseTabs(index){
      this.titleIndex = index
    }
  }
}
</script>

<style>
.active-color{
  color: #2d8cf0;
}
.header-tabs-title1{
  font-size: 16px;
  padding-bottom: 10px;
}
.header-tabs-icons {
  width: 100%;
  border: 1px solid #2d8cf0;
}

main {
  width: 1800px;
  margin: 0 auto;
}

.main-image {
  width: 1800px;
  height: 100%;
}

a {
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  transition: all 0.35s;
  color: #474157;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.footer-view {
  background-color: #222222;
  padding: 20px 0;
  color: rgba(255, 255, 255, 0.3);
  text-align: center;
}

.header-tabs-title {
  margin-right: 20px;
}

.header-tabs-view {
  display: flex;
  align-items: center;
}

.header-view {
  width: 1200px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#app {
  background-color: #F1F6FD;
}</style>
